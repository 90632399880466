import { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { LinkAnchors } from "../ContractEdit";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { dataContracts } from "../../../data/dataContracts";
import { Status } from "../../../data/types";
import { contractMainContractDataState } from "../../../state/contractMainContractDataState";
import { contractStatusState } from "../../../state/contractStatusState";
import { ContractSaveError, getEditStatus, handleError } from "../ContractPage";
import { ContractEditError } from "../ContractEditError";
import { useTranslation } from "react-i18next";
import { Input } from "../../../components/interactions/Input/Input";
import { MCC } from "../../../components/mcc/MCC";
import { VAT } from "../../../components/vat/VAT";
import { contractErrorState, contractSaveState } from "../../../state/contractSaveState";
import { Address, ContractFeature, RegonStatus, VatStatus } from "../../../model/contract/contractType";
import { contractDocumentsState } from "../../../state/contractDocumentsState";
import { clearCas, getCountryOpts, getLanguageOpts } from "../../../components/utils";
import { Form, FormContainer } from "../../../components/form/Form";
import { TextInput } from "../../../components/form/TextInput";
import { RequiredValidator } from "../../../components/form/validators/RequiredValidator";
import { MinLengthValidator } from "../../../components/form/validators/MinLengthValidator";
import { MaxLengthValidator } from "../../../components/form/validators/MaxLengthValidator";
import { Select } from "../../../components/form/Select";
import { Language } from "../../../i18n";
import { hasRealErrors } from "../../../components/form/FormHelpers";
import { FormName } from "../menus/ContractEditMenu";
import { RadioGroup } from "../../../components/form/RadioGroup";
import { ScrollPositionAnchor } from "../../../components/scrollPosition/ScrollPositionAnchor";
import { Country } from "../../../model/common/commonType";
import { SectionFieldSet } from "../../../components/sectionFieldSet/SectionFieldSet";

import { COUNTRY_ID_TITLE } from "../../../components/NewOrganisationOverlay/NewContractOverlay";
import { AddressWithSearch } from "../../../components/address/AddressWithSearch.module";
import { NumberValidator } from "../../../components/form/validators/NumberValidator";
import { Checkboxes } from "../../../components/interactions/Checkboxes/Checkboxes";
import useEnvironment from "../../../hooks/useEnvironment";
import { Alternative } from "../../../components/interactions/InputTypes";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { T } from "../../../components/translation/T";

const MCC_CODE_LENGTH = 4;

export function CompanyData() {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState<ContractSaveError | null>(null);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [mainData, setMainData] = useRecoilState(contractMainContractDataState);
  const setDataSaved = useSetRecoilState(contractSaveState);
  const setDataError = useSetRecoilState(contractErrorState);
  const contractStatus = useRecoilValue(contractStatusState);
  const setDocuments = useSetRecoilState(contractDocumentsState);
  const prevMcc = useRef<string>(mainData.mcc || "");
  const prevSaved = useRef<string>(JSON.stringify(clearCas(mainData)));
  const country = useMemo(() => contractStatus.country, [contractStatus]);
  const [mainBusinessActivity, setMainBusinessActivity] = useState<Alternative<string>[]>([]);
  const [apiError, setApiError] = useState(false);

  const formContainer = useRef<FormContainer>();

  const { isProduction } = useEnvironment();

  const onClose = useCallback(() => {
    setError(null);
  }, []);

  const loadDocumentsIfMCCUpdated = useCallback(() => {
    if (!mainData.mcc) {
      return;
    }

    if (mainData.mcc === prevMcc.current) {
      return;
    }

    if (mainData.mcc.length !== MCC_CODE_LENGTH) {
      return;
    }

    prevMcc.current = mainData.mcc;
    dataContracts
      .loadDocuments(contractStatus.contractId)
      .then((docs) => setDocuments(docs))
      .catch((err) => {
        // Just let it be. We won't list additional docs in this case
        console.error(err);
      });
  }, [mainData.mcc, prevMcc, contractStatus.contractId, setDocuments]);

  const onSave = useCallback(
    (updated) => {
      const stringCopy = JSON.stringify(clearCas(updated));
      if (prevSaved.current === stringCopy) {
        return;
      } else {
        prevSaved.current = stringCopy;
      }

      dataContracts
        .saveMainContractData(contractStatus.contractId, {
          ...updated,
        })
        .then((data) => {
          setStatus(Status.DEFAULT);
          setMainData({ ...updated, cas: data.cas });
          setDataSaved((dataSaved) =>
            dataSaved.concat({
              date: new Date(),
            })
          );
          loadDocumentsIfMCCUpdated();
        })
        .catch((err) => {
          prevSaved.current = "";
          setStatus(Status.DEFAULT);
          handleError(err, setError);
          setDataError((dataErrors) =>
            dataErrors.concat({
              date: new Date(),
            })
          );
        });
    },
    [contractStatus.contractId, setMainData, setDataError, setDataSaved, loadDocumentsIfMCCUpdated]
  );

  const retry = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(onSave, 500);
  }, [onSave]);

  const reclaimAndSave = useCallback(() => {
    setError(null);
    setStatus(Status.PENDING);
    setTimeout(() => {
      dataContracts.claimContract(contractStatus.contractId).then(onSave).catch(onSave); // We're lazy, execute save again, which will fail,
      // and propagate error to Overlay
    }, 500);
  }, [contractStatus.contractId, onSave]);

  const inputStatus = useMemo(() => {
    return getEditStatus(contractStatus.edit, status);
  }, [contractStatus.edit, status]);

  const originOpts = useMemo(() => {
    const opts = getCountryOpts(i18n.language as Language, t, true);
    return opts.filter((opt) => opt.value !== contractStatus.country);
  }, [t, i18n.language, contractStatus.country]);

  const APPLICABLE_BUSINESS_PRACTICES = useMemo(
    () => [
      {
        value: "N/A",
        text: t("N/A"),
      },
      {
        value: "Reselling",
        text: t("Reselling"),
      },
      {
        value: "Drop Shipping",
        text: t("Drop Shipping"),
      },
      {
        value: "Multi Level Marketing",
        text: t("Multi Level Marketing"),
      },
      {
        value: "Stored Value Items",
        text: t("Stored Value Items"),
      },
      {
        value: "Payment Aggregation",
        text: t("Payment Aggregation"),
      },
      {
        value: "Creator Economy/UGC",
        text: t("Creator Economy/UGC"),
      },
      {
        value: "Healthcare/Medical/Dental Services",
        text: t("Healthcare/Medical/Dental Services"),
      },
      {
        value: "High Value Retail",
        text: t("High Value Retail"),
      },
    ],
    [t]
  );

  const INTENDED_USE_OF_PRODUCTS = useMemo(
    () => [
      {
        value: "In-store/point of sales (POS)",
        text: t("In-store/point of sales (POS)"),
      },
      {
        value: "Call Centre Application, Mail Order/Telephone Order",
        text: t("Call Centre Application, Mail Order/Telephone Order"),
      },
      {
        value: "App, Linkplus, Billing and Settlement plan",
        text: t("App, Linkplus, Billing and Settlement plan"),
      },
    ],
    [t]
  );

  const PURPOSE_OF_RELATIONSHIP = useMemo(
    () => [
      {
        value: "Sales of goods or service",
        text: t("Sales of goods or service"),
      },
      {
        value: "Donations",
        text: t("Donations"),
      },
      {
        value: "(International) Money Transfer",
        text: t("(International) Money Transfer"),
      },
      {
        value: "Facilitating third party sale of goods or services",
        text: t("Facilitating third party sale of goods or services"),
      },
      {
        value: "Payment Facilitators",
        text: t("Payment Facilitators"),
      },
      {
        value: "Reselling",
        text: t("Reselling"),
      },
      {
        value: "OCT original credit transactions",
        text: t("OCT original credit transactions"),
      },
      {
        value: "Other",
        text: t("Other"),
      },
    ],
    [t]
  );

  const YES_OR_NO = useMemo(
    () => [
      {
        value: "Yes",
        text: t("Yes"),
      },
      {
        value: "No",
        text: t("No"),
      },
    ],
    [t]
  );

  useEffect(() => {
    if (isProduction) return;

    const fetchBusinessActivity = async () => {
      setApiError(false);
      dataContracts
        .getLookup(contractStatus.contractId)
        .then((businessData) => {
          const businessAlternatives: Alternative<string>[] = businessData.values.map((value) => ({
            value: value,
            text: value,
          }));

          setMainBusinessActivity(businessAlternatives);
        })
        .catch((err) => {
          setApiError(true);
          console.log(err);
        });
    };

    fetchBusinessActivity();
  }, [contractStatus.contractId, isProduction]);

  const CUSTOMER_OR_PARENT_IS = useMemo(
    () => [
      {
        value: "Regulated Financial Institutions, from a low risk jurisdiction",
        text: t("Regulated Financial Institutions, from a low risk jurisdiction"),
      },
      {
        value: "Publicly Listed Companies, on an EU-recognised stock exchange",
        text: t("Publicly Listed Companies, on an EU-recognised stock exchange"),
      },
      {
        value: "Government Owned Entities, from a low risk jurisdiction",
        text: t("Government Owned Entities, from a low risk jurisdiction"),
      },
      {
        value: "Regulated entities, from a low risk jurisdiction (e.g. regulated charities)",
        text: t("Regulated entities, from a low risk jurisdiction (e.g. regulated charities)"),
      },
      {
        value: "Trust; Fund; Charity; Club & Society",
        text: t("Trust; Fund; Charity; Club & Society"),
      },
      {
        value: "Other",
        text: t("Other"),
      },
    ],
    [t]
  );
  const languageOpts = useMemo(() => {
    return getLanguageOpts(i18n.language as Language, contractStatus.country, t);
  }, [t, i18n.language, contractStatus.country]);

  const active = useMemo(() => inputStatus !== Status.DISABLED, [inputStatus]);

  const onAddressChange = useCallback(
    (address: Address) => {
      setMainData((data) => ({
        ...data,
        primaryAddress: address,
      }));

      if (formContainer.current) {
        const realErrors = hasRealErrors(formContainer.current);
        if (!realErrors) {
          onSave({ ...mainData, primaryAddress: address });
        }
      }
    },
    [setMainData, onSave, mainData]
  );

  return (
    <Form
      formContainer={formContainer}
      onSaveTrigger={(event, form) => {
        const realErrors = hasRealErrors(form);
        if (!realErrors) {
          onSave(mainData);
        }
      }}
      name={FormName.MAIN_DATA}
    >
      <ScrollPositionAnchor id={LinkAnchors.COMPANY_DATA.anchor} />
      <ContractEditError
        error={error}
        setError={setError}
        retry={retry}
        onClose={onClose}
        reclaimAndSave={reclaimAndSave}
      />
      <SectionFieldSet headerTitle={t(LinkAnchors.COMPANY_DATA.name)} formName={FormName.MAIN_DATA}>
        <div className={"double-auto-columns"}>
          <div>
            <div className="fw-600">{t("Acceptance agreement")}</div>
            <p className="m-y-10">
              {t("In the past a card acceptance agreement of the Merchant was terminated prematurely")}
            </p>
          </div>

          <RadioGroup
            label={t("Acceptance")}
            name="acceptance"
            onChange={(value) => {
              const copy = { ...mainData, acceptance: value === "true" };
              setMainData(copy);
              onSave(copy);
            }}
            value={mainData.acceptance ? "true" : "false"}
            disabled={!active}
            alternatives={[
              {
                text: t("No"),
                value: "false",
              },
              {
                text: t("Yes"),
                value: "true",
              },
            ]}
          />

          <div>
            <TextInput
              onChange={(value) => setMainData({ ...mainData, companyName: value })}
              label={t("Legal name")}
              value={mainData.companyName}
              name="companyName"
              validators={[
                new RequiredValidator(t("Legal name is required")),
                new MinLengthValidator(
                  2,
                  t("Legal name must be at least {{min}} characters", {
                    min: 2,
                  })
                ),
                new MaxLengthValidator(
                  60,
                  t("Legal name cannot be longer than {{min}} characters", {
                    min: 60,
                  })
                ),
              ]}
              disabled={!active}
            />
          </div>

          {/* We don't care about Company registration id (KRS / Regon) in Poland, so to not cause confusion we hide it.
            VAT-ID (NIP) is used instead and is the information we pass downwards in the end */}

          {/* TODO: Perhaps Regon will also be relevant for other countries?*/}
          {country === Country.POLAND ? (
            <TextInput
              onChange={(value) => setMainData({ ...mainData, regonNumber: value })}
              disabled={mainData.regonResult === RegonStatus.FOUND ? true : !active}
              value={mainData.regonNumber}
              label={t("REGON")}
              message={
                mainData.regonResult === RegonStatus.FOUND
                  ? t("Regon found from registry")
                  : t("Regon not found, added manually")
              }
              name="regonNumber"
            />
          ) : (
            <Input
              onChange={() => {}}
              status={Status.DISABLED}
              value={mainData.companyRegistrationId}
              label={`${t("Registry no.")} (${COUNTRY_ID_TITLE[country]})`}
              message={t("Can not be changed")}
              name="companyRegistrationId"
            />
          )}

          <VAT
            onChange={(value) => setMainData({ ...mainData, vatNumber: value })}
            status={VatStatus.VALID === mainData.vatResult ? Status.DISABLED : inputStatus}
            vatResult={mainData.vatResult}
            value={mainData.vatNumber}
            contractId={contractStatus.contractId}
          />

          <div>
            <MCC
              onChange={(value) => setMainData({ ...mainData, mcc: value })}
              status={inputStatus}
              selectedMccCode={mainData.mcc || ""}
              name="mcc"
              active={active}
              country={contractStatus.country}
              contractType={mainData.contractType}
            />
          </div>
        </div>

        <div className="m-y-30">
          <AddressWithSearch
            label="Business address"
            address={mainData.primaryAddress}
            onChange={onAddressChange}
            addressRequiredFields={{
              street: true,
              city: true,
              postalCode: true,
              countryCode: true,
              streetNumber: true,
            }}
            disabled={!active}
            country={country}
          />
        </div>

        <div className={"double-auto-columns"}>
          {!contractStatus.enabledFeatures.includes(ContractFeature.ADVANCED_PRICING) && (
            <TextInput
              onChange={(value) => setMainData({ ...mainData, cardholderStatementText: value })}
              label={t("Short address")}
              hint={t("For cardholder statement")}
              placeholder={t("Max 21 characters")}
              value={mainData.cardholderStatementText}
              name="cardholderStatementText"
              validators={[
                new RequiredValidator(t("Short adress is required")),
                new MaxLengthValidator(
                  21,
                  t("Short adress cannot be longer than {{min}} characters", {
                    min: 21,
                  })
                ),
              ]}
              disabled={!active}
            />
          )}

          <div>
            <TextInput
              onChange={(value) => setMainData({ ...mainData, website: value })}
              label={t("Company website")}
              placeholder={t("www.company.com")}
              value={mainData.website}
              validators={[
                new MaxLengthValidator(
                  50,
                  t("Company website cannot be longer than {{min}} characters", {
                    min: 50,
                  })
                ),
              ]}
              disabled={!active}
            />
          </div>

          <div>
            <Select
              onChange={(value) => {
                const copy = { ...mainData, language: value as Language };
                setMainData(copy);
                onSave(copy);
              }}
              disabled={!active}
              label={t("Communication language")}
              alternatives={languageOpts}
              value={mainData.language}
            />
          </div>

          <div>
            <Select
              onChange={(value) => {
                const copy = { ...mainData, countryOfOrigin: value };
                setMainData(copy);
                onSave(copy);
              }}
              disabled={!active}
              alternatives={originOpts}
              value={mainData.countryOfOrigin}
              label={t("Country of origin")}
              hint={t(
                "Mandatory field for state-controlled Merchants (embassies, consulates, chambers of commerce, etc.) whose business location differs from the country of origin."
              )}
            />
          </div>
          {!isProduction && (
            <>
              <div>
                <Select
                  onChange={(value) => {
                    const copy = { ...mainData, selectIfCompanyOrParentIs: value };
                    setMainData(copy);
                    onSave(copy);
                  }}
                  disabled={!active}
                  alternatives={CUSTOMER_OR_PARENT_IS}
                  value={mainData.selectIfCompanyOrParentIs}
                  label={t("Please select if the customer or its parent is")}
                />
              </div>
              <div>
                <TextInput
                  type="date"
                  onChange={(value) => {
                    const copy = { ...mainData, dateOfIncorporation: value };
                    setMainData(copy);
                    onSave(copy);
                  }}
                  name="dateOfIncorporation"
                  label={t("Date of Incorporation")}
                  disabled={!active}
                  placeholder="yyyy-mm-dd"
                  value={mainData.dateOfIncorporation}
                  validators={[new RequiredValidator(t("Date of incorporation is required"))]}
                />
              </div>
              <div>
                <Select
                  onChange={(value) => {
                    const copy = { ...mainData, supervisedByRegulatoryAuth: value };
                    setMainData(copy);
                    onSave(copy);
                  }}
                  disabled={!active}
                  alternatives={YES_OR_NO}
                  value={mainData.supervisedByRegulatoryAuth}
                  label={t("Is the company supervised by a regulatory authority or any other licensing body")}
                  validators={[new RequiredValidator(t("Is the company supervised is required"))]}
                />
              </div>
              <div>
                <Select
                  onChange={(value) => {
                    const copy = { ...mainData, areFundsRemittedToThirdParty: value };
                    setMainData(copy);
                    onSave(copy);
                  }}
                  disabled={!active}
                  alternatives={YES_OR_NO}
                  value={mainData.areFundsRemittedToThirdParty}
                  label={t("Are funds remitted to a third party")}
                  validators={[new RequiredValidator(t("Funds remitted to a third party is required"))]}
                />
              </div>
              <div>
                <TextInput
                  onChange={(value) => {
                    const copy = { ...mainData, totalEstimateAnnualTurnover: value };
                    setMainData(copy);
                    onSave(copy);
                  }}
                  name="totalEstimateAnnualTurnover"
                  label={t("Total estimated annual turnover")}
                  value={mainData.totalEstimateAnnualTurnover}
                  validators={[
                    new RequiredValidator(t("Total estimated annual turnover is required")),
                    new NumberValidator(t("Total estimated annual turnover must be a number")),
                    new MaxLengthValidator(
                      50,
                      t("Total estimated annual turnover cannot be longer than {{min}} characters", {
                        min: 50,
                      })
                    ),
                  ]}
                  disabled={!active}
                />
              </div>
              <div>
                <TextInput
                  onChange={(value) => {
                    const copy = { ...mainData, businessModel: value };
                    setMainData(copy);
                    onSave(copy);
                  }}
                  name="businessModel"
                  label={t("Business model")}
                  value={mainData.businessModel}
                  validators={[
                    new RequiredValidator(t("Business model turnover is required")),
                    new MaxLengthValidator(
                      50,
                      t("Business model cannot be longer than {{min}} characters", {
                        min: 50,
                      })
                    ),
                  ]}
                  disabled={!active}
                />
              </div>
              <div>
                <Checkboxes
                  disabled={!active}
                  label={t("What is the purpose of the relationship")}
                  onChange={(value) => {
                    const copy = { ...mainData, purposeOfRelationship: value };
                    setMainData(copy);
                    onSave(copy);
                  }}
                  values={mainData.purposeOfRelationship}
                  alternatives={PURPOSE_OF_RELATIONSHIP}
                />
              </div>
              <div>
                <Checkboxes
                  disabled={!active}
                  label={t("What is the inteded use of products")}
                  onChange={(value) => {
                    const copy = { ...mainData, intendedUseOfProduct: value };
                    setMainData(copy);
                    onSave(copy);
                  }}
                  values={mainData.intendedUseOfProduct}
                  alternatives={INTENDED_USE_OF_PRODUCTS}
                />
              </div>
              <div>
                <Checkboxes
                  disabled={!active}
                  label={t("Select the applicable business practice")}
                  onChange={(value) => {
                    const copy = { ...mainData, applicableBusinessPractices: value };
                    setMainData(copy);
                    onSave(copy);
                  }}
                  values={mainData.applicableBusinessPractices}
                  alternatives={APPLICABLE_BUSINESS_PRACTICES}
                />
              </div>
              <div>
                {apiError && (
                  <ErrorBox>
                    <T>There was a problem getting terminal prices.</T>
                  </ErrorBox>
                )}
                <Select
                  onChange={(value) => {
                    const copy = { ...mainData, mainBusinessActivity: value };
                    setMainData(copy);
                    onSave(copy);
                  }}
                  disabled={!active}
                  alternatives={mainBusinessActivity}
                  value={mainData.mainBusinessActivity}
                  label={t("The main business activity the company conduct")}
                  validators={[new RequiredValidator(t("Main business activity is required"))]}
                />
              </div>
            </>
          )}
        </div>
      </SectionFieldSet>
    </Form>
  );
}
